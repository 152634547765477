import Appbar from "./appbar";
import SideBar from "./sidebar";
import { useState } from "react";
import { Modal, Button } from "flowbite-react";
import { useAppStore } from "../stores/app-store";
import { useShallow } from "zustand/shallow";
import { useAuthStore } from "../stores/auth-store";
import NavBar from "./navbar";
import { Outlet, useNavigate } from "react-router-dom";

export default function Layout() {
  const user = useAuthStore((state) => state.user);
  const { activeTab, setActiveTab } = useAppStore(
    useShallow((state) => ({
      activeTab: state.activeTab,
      setActiveTab: state.setActiveTab,
    }))
  );
  const [openModal, setOpenModal] = useState(false);
  function toggleModal(a: boolean) {
    setOpenModal(a);
  }
  const navigate = useNavigate();
  return (
    <div className="w-screen h-full flex flex-col">
      <Appbar
        toggleModal={toggleModal}
        active={activeTab}
        handleActiveTab={setActiveTab}
        userName={user?.pharmacy || ""}
      />
      <div className="w-full flex h-full">
        <SideBar
          toggleModal={toggleModal}
          active={activeTab}
          handleActiveTab={setActiveTab}
        />
        <div className="w-full relative h-full">
          <Outlet />
          <NavBar active={activeTab} handleActiveTab={setActiveTab} />
        </div>
      </div>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className="uppercase text-sm sm:text-lg">
          logout
        </Modal.Header>
        <Modal.Body>
          <p className="text-base w-full text-center leading-relaxed text-gray-500">
            Are you sure you want to logout from PHARMACANDA?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-center max-sm:flex-col flex gap-4">
          <Button
            className="sm:min-w-40 max-sm:min-w-24 w-full max-sm:max-w-40"
            onClick={() => {
              navigate("/login", { replace: true });
              setOpenModal(false);
            }}
          >
            logout
          </Button>
          <Button
            className="sm:min-w-40 max-sm:min-w-24 w-full max-sm:max-w-40"
            color="gray"
            onClick={() => setOpenModal(false)}
          >
            cancle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
