import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, FileInput, Label, Modal } from "flowbite-react";
import { useState } from "react";
import { Patient } from "../../../types/patient";
import PatientSelect from "../../../components/PatientSelect";
import apiCaller from "../../../utils/api-caller";
import * as axios from "axios";
import Reminder from "../../../components/inputs/reminder";
import RecurrencyInput from "../../../components/inputs/recurrency";
import { useAuthStore } from "../../../stores/auth-store";
export default function AddEventModal({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) {
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [recurrenceType, setRecurrenceType] = useState("none");
  const [recurrenceInterval, setRecurrenceInterval] = useState(1);
  const [patient, setPatient] = useState<Patient>();
  const [reminders, setReminders] = useState<
    { minutes: number; method: "email" | "popup" }[]
  >([{ minutes: 30, method: "popup" }]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFiles(Array.from(e.target.files));
    }
  };
  const handleAddReminder = () => {
    setReminders([...reminders, { minutes: 30, method: "popup" }]);
  };
  const handleRemoveReminder = (index: number) => {
    setReminders(reminders.filter((_, i) => i !== index));
  };
  const queryClient = useQueryClient();
  const addEventMutation = useMutation({
    mutationFn: (data: FormData) =>
      apiCaller<void>({
        method: "POST",
        url: `/followups/${patient?._id}`,
        body: data,
        formData: true,
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["followups"] });
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      onClose();
    },
  });
  const calender = useAuthStore((state) => state.user?.calender);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const recurrenceRule =
      recurrenceType !== "none"
        ? [
            `RRULE:FREQ=${recurrenceType.toUpperCase()};INTERVAL=${recurrenceInterval}`,
          ]
        : undefined;

    const data = {
      summary,
      description,
      start: {
        dateTime: new Date(startDateTime).toISOString(),
        timeZone: calender?.timeZone,
      },
      end: {
        dateTime: new Date(endDateTime).toISOString(),
        timeZone: calender?.timeZone,
      },
      recurrence: recurrenceRule,
      reminders: {
        useDefault: false,
        overrides: reminders,
      },
    };
    const formData = axios.toFormData(data);
    if (files?.length) formData.append("file", files[0]);
    addEventMutation.mutate(formData as any);
  };
  return (
    <Modal show={show} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <Modal.Header>Create Followup</Modal.Header>
        <Modal.Body className="max-h-[60vh] overflow-auto">
          <div className="space-y-3 ">
            <div>
              <label
                htmlFor="summary"
                className="block text-sm font-medium text-gray-700"
              >
                Event Title
              </label>
              <input
                type="text"
                id="summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>
            <PatientSelect
              label="select a patient"
              onSelect={(patent) => setPatient(patent)}
            />
            <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-4">
              <div>
                <label
                  htmlFor="startDateTime"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Start Date & Time
                </label>
                <input
                  type="datetime-local"
                  id="startDateTime"
                  value={startDateTime}
                  onChange={(e) => setStartDateTime(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label
                  htmlFor="endDateTime"
                  className="block text-sm mb-2 gap-2 font-medium text-gray-700"
                >
                  End Date & Time
                </label>
                <input
                  type="datetime-local"
                  id="endDateTime"
                  value={endDateTime}
                  onChange={(e) => setEndDateTime(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                  required
                />
              </div>
            </div>
            <RecurrencyInput
              recurrenceType={recurrenceType}
              recurrenceInterval={recurrenceInterval}
              setRecurrenceType={setRecurrenceType}
              setRecurrenceInterval={setRecurrenceInterval}
            />

            <div className="flex flex-col w-full">
              <div className="mb-2 block">
                <Label
                  htmlFor="file-upload"
                  className="block text-sm font-medium text-gray-700"
                  value="Upload file"
                />
              </div>
              <FileInput
                id="file-upload"
                multiple
                className="bg-white"
                onChange={handleFileChange}
              />
            </div>
            <Reminder
              handleAddReminder={handleAddReminder}
              handleRemoveReminder={handleRemoveReminder}
              reminders={reminders}
              setReminders={setReminders}
            />
            <div>
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                rows={3}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className=" border-0 justify-center gap-4">
          <Button
            className="sm:min-w-40 max-sm:min-w-24 max-sm:max-w-40 w-full"
            type="submit"
          >
            Add
          </Button>
          <Button className="sm:min-w-40 max-sm:min-w-24 max-sm:max-w-40 w-full !bg-neutral-500">
            Cancle
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
