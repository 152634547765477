import { RequestsStatus } from "../../../types/request";

export default function getRequestsStatusLable(
  status: Partial<RequestsStatus>
) {
  let res = "";
  if (status.totalNewRequest) res += `${status.totalNewRequest}R`;
  if (status.totalTickets) res += `-${status.totalTickets}T`;
  return res;
}
