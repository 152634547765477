import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, ButtonGroup, Modal, Select } from "flowbite-react";
import { useState } from "react";
import { PAGINATION_SIZE } from "../../../constants/pagination";
import { FolloupEventMetadata } from "../../../types/followup";
import { BsFillTrashFill } from "react-icons/bs";
import apiCaller from "../../../utils/api-caller";
import { useAuthStore } from "../../../stores/auth-store";
import moment from "moment";
import { setFollowup } from "../../../stores/followup-store";
import { useNavigate } from "react-router-dom";
import DateFilter from "../../../components/filters/date-filter";
import { useDebouncedCallback } from "use-debounce";
import { SquareSkeleton } from "../../../components/skeleton";

export default function FollowupsTable() {
  const [query, setQuery] = useState<{
    name?: string;
    expand: boolean;
    from?: Date | null;
    to?: Date | null;
  }>({
    from: null,
    to: null,
    expand: false,
  });
  const [openModal, setOpenModal] = useState<{ add: boolean; delete: boolean }>(
    { add: false, delete: false }
  );
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [selectedItem, setSelectedItem] = useState<{
    id: string;
    title: string;
    patient: string;
  }>();
  const calenderId = useAuthStore((state) => state.user?.calender?.id);
  const followups = useQuery({
    queryKey: ["followups", pageToken, query],
    queryFn: async () => {
      const filter: gapi.client.calendar.EventsListParameters = {
        calendarId: calenderId as string,
        timeMin: query.from
          ? query.from.toISOString()
          : new Date().toISOString(),
        singleEvents: query.expand,
        maxResults: PAGINATION_SIZE,
      };
      if (query.to) {
        filter["timeMax"] = query.to.toISOString();
      }
      if (pageToken) {
        filter["pageToken"] = pageToken;
      }
      if (query.name) {
        filter["sharedExtendedProperty"] = `name=${query.name}`;
      }
      const result_3 = await apiCaller<gapi.client.calendar.Events>({
        method: "GET",
        url: "/followups",
        params: filter,
      });
      const nextPageToken = result_3.nextPageToken;
      setNextPageToken(nextPageToken);
      return result_3.items;
    },
    enabled: !!calenderId,
  });
  const queryClient = useQueryClient();
  const deleteFollowup = useMutation({
    mutationFn: (id: string) =>
      apiCaller<void>({
        method: "DELETE",
        url: `/followups/${id}`,
      }),
    onSuccess() {
      const iframe = document.getElementById(
        "google-calender-iframe"
      ) as HTMLIFrameElement;
      if (iframe) {
        const src = iframe.src;
        iframe.src = "";
        iframe.src = src;
      }
      setOpenModal((prev) => ({ ...prev, delete: false }));
      setSelectedItem(undefined);
      queryClient.invalidateQueries({ queryKey: ["followups"] });
    },
  });
  const navigate = useNavigate();
  const onSearch = useDebouncedCallback((e: any) => {
    setQuery((prev) => ({ ...prev, name: e.target.value }));
  }, 300);
  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:px-2 sm:px-4">
      <div
        className={
          "shadow-sm w-full flex gap-2 items-end bg-blue-50 p-4 rounded-md"
        }
      >
        <div className="relative w-full max-w-md">
          <label className="block mb-2 text-sm font-medium text-gray-900 capitalize">
            search patient
          </label>
          <input
            type="search"
            onChange={onSearch}
            className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white h-[41.6px] focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search  ..."
          />
        </div>
        <DateFilter
          from={query?.from}
          to={query?.to}
          onChange={(e) => setQuery((prev) => ({ ...prev, ...e }))}
        />

        <div className="flex flex-col w-1/2 max-w-xs">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            Expand
          </label>
          <Select
            id="countries"
            className="bg-white rounded-lg w-full"
            onChange={(e) => {
              const id = e.target.options[e.target.selectedIndex].dataset.id;
              setQuery((prev) => ({ ...prev, expand: id === "true" }));
            }}
          >
            {[
              { name: "Single", id: false },
              { name: "Expand", id: true },
            ].map((item, index) => {
              return (
                <option key={item.name} data-id={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
      <div className="flex flex-col justify-between">
        <div className="relative overflow-auto sm:max-h-[62vh] sm:min-h-[62vh] max-sm:max-h-[47vh] max-sm:min-h-[47vh]">
          <table className="w-full table-auto shadow-md rounded-b-md">
            <thead className="bg-neutral-200 sticky top-0">
              <th className="text-xs sm:text-sm px-2 py-3">title</th>
              <th className="text-xs sm:text-sm px-2 py-3 ">patient</th>
              <th className="text-xs sm:text-sm px-2 py-3 ">phone</th>
              <th className="text-xs sm:text-sm px-2 py-3">date</th>
              <th className="text-xs sm:text-sm px-2 py-3">attachments</th>
              <th className="text-xs sm:text-sm px-2 py-3">description</th>
              <th className="text-xs sm:text-sm px-2 py-3"></th>
            </thead>
            <tbody className="divide-y">
              {followups.isLoading ? (
                <>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td></td>
                  </tr>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td></td>
                  </tr>
                  <tr className="bg-white border-x border-neutral-200 cursor-pointer ">
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center gap-1 font-medium text-gray-900 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td></td>
                  </tr>
                </>
              ) : (
                followups.data?.map((followup) => {
                  return (
                    <tr
                      key={followup.id}
                      className="bg-white border-x border-neutral-200 cursor-pointer"
                      onClick={() => {
                        setFollowup(followup);
                        navigate(`/followups/${followup.id}`);
                      }}
                    >
                      <td
                        colSpan={2}
                        className="text-xs sm:text-sm px-2 whitespace-nowrap w-[150px] text-wrap pr-0 flex items-center  gap-1 font-medium text-gray-900 "
                      >
                        {followup.summary}
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap">
                        {
                          (
                            followup.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.name
                        }
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap">
                        {(
                          followup.extendedProperties
                            ?.shared as FolloupEventMetadata
                        )?.phone || "-"}
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap">
                        {moment(followup.start.dateTime).format(
                          "DD MMM YYYY, hh:mm"
                        )}
                      </td>
                      <td className="text-xs sm:text-sm px-1 text-nowrap text-center">
                        {(
                          followup.extendedProperties
                            ?.shared as FolloupEventMetadata
                        )?.file_name
                          ? (
                              followup.extendedProperties
                                ?.shared as FolloupEventMetadata
                            ).file_name
                          : "_"}
                      </td>
                      <td className="text-xs sm:text-sm px-1">
                        {followup.description}
                      </td>
                      <td
                        onClick={(e) => {
                          e.stopPropagation();
                          setOpenModal((prevState) => ({
                            ...prevState,
                            delete: true,
                          }));
                          setSelectedItem({
                            id: followup.id,
                            title: followup.summary,
                            patient: (
                              followup.extendedProperties
                                ?.shared as FolloupEventMetadata
                            )?.name as string,
                          });
                        }}
                        className="text-xs sm:text-sm px-2"
                      >
                        <button className="rounded-full flex items-center justify-center w-8 h-8 hover:bg-primary-400">
                          <BsFillTrashFill className="text-blue-600" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <ButtonGroup className="mt-1 w-full items-center justify-center">
          <Button
            onClick={() => {
              setPageToken(undefined);
              setNextPageToken(undefined);
            }}
            disabled={!pageToken}
          >
            Reset
          </Button>
          <Button
            disabled={!nextPageToken}
            onClick={() => {
              setPageToken(nextPageToken);
              setNextPageToken(undefined);
            }}
          >
            Next
          </Button>
        </ButtonGroup>
      </div>
      <Modal
        show={openModal.delete}
        onClose={() =>
          setOpenModal((prevState) => ({ ...prevState, delete: false }))
        }
      >
        <Modal.Header className="uppercase">delete followup</Modal.Header>
        <Modal.Body className="">
          <p className="text-base w-full text-center leading-relaxed text-gray-500">
            Are you sure you want to delete this followup ({selectedItem?.title}
            )?{" "}
          </p>
        </Modal.Body>
        <Modal.Footer className="border-0 max-sm:flex-col justify-center gap-4">
          <Button
            className="min-w-40"
            onClick={() => {
              deleteFollowup.mutate(selectedItem?.id as string);
            }}
          >
            delete
          </Button>
          <Button
            className="min-w-40"
            color="gray"
            onClick={() =>
              setOpenModal((prevState) => ({ ...prevState, delete: false }))
            }
          >
            cancle
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
