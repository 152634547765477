import { useEffect, useState } from "react";
import { useAuthStore } from "../stores/auth-store";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import apiCaller from "../utils/api-caller";
import { Patient } from "../types/patient";
import { PAGINATION_SIZE } from "../constants/pagination";
import { DEFAULT_CACHE_CONFIG } from "../constants/api";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";

export default function PatientSelect({
  onSelect,
  label = "Select an option",
  defaultValue,
}: {
  label?: string;
  onSelect: (patient: Patient) => void;
  defaultValue?: string;
}) {
  const userId = useAuthStore((state) => state.user?._id);
  const { data } = useQuery({
    queryKey: ["patients", 1],
    queryFn: () =>
      apiCaller<{
        length: number;
        patients: Patient[];
      }>({
        method: "GET",
        url: `/owner/${userId}/patients`,
        params: { page: 1, size: PAGINATION_SIZE },
      }),
    placeholderData: keepPreviousData,
    ...DEFAULT_CACHE_CONFIG,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState<boolean>(false);

  const filteredPatients = data?.patients.filter((patient) =>
    patient.userName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setSearchTerm(defaultValue || "");
  }, [defaultValue]);

  return (
    <form className="w-full relative mx-auto">
      <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-white">
        {label}
      </label>
      <div className="relative">
        <input
          type="text"
          placeholder="Search for a patient..."
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={searchTerm}
          onClick={() => setOpen(!open)}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <span
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 transition-transform duration-200 ${
            open ? "rotate-180" : ""
          }`}
        >
          <MdOutlineKeyboardArrowDown />
        </span>
      </div>
      {open && filteredPatients && filteredPatients.length > 0 && (
        <div className="mt-2 w-full bg-neutral-100 z-10 absolute top-14 left-0 shadow-xl rounded-lg max-h-60 overflow-y-auto">
          {filteredPatients.map((patient) => (
            <div
              key={patient._id}
              className="p-2 hover:bg-blue-100 cursor-pointer"
              onClick={() => {
                onSelect(patient);
                setSearchTerm(patient.userName);
                setOpen(false);
              }}
            >
              {patient.userName}
            </div>
          ))}
        </div>
      )}
    </form>
  );
}
