import { Message } from "../../types/message";
import { getFileLink, isImage } from "../../utils/file";
import Avatar from "./Avatar";
import FileCard from "./FileCard";
import FilePreview from "./FilePreview";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";

export default function FileMessage(props: Message & { userId: string }) {
    const fromUser = props.sender._id === props.userId;
    const { file, sender, state, createdAt, message } = props;
    const [previewImage, setPreviewImage] = useState<string | null>(null);

    const handleFileClick = () => {
        if (!file) return;

        if (isImage(file.name)) {
            setPreviewImage(getFileLink(file.url));
        } else {
            const link = document.createElement("a");
            link.href = getFileLink(file.url);
            link.download = file.name;
            link.click();
        }
    };

    return (
        <>
            <div className={`flex items-start gap-3 ${fromUser ? "flex-row-reverse" : "flex-row"}`}>
                <Avatar
                    userName={props.sender.userName}
                    fromUser={fromUser}
                />

                <FileCard
                    file={file}
                    message={message}
                    createdAt={createdAt}
                    state={state}
                    fromUser={fromUser}
                    onFileClick={handleFileClick}
                    senderUserName={sender.userName}
                />
            </div>

            <AnimatePresence>
                {previewImage && (
                    <FilePreview
                        imageUrl={previewImage}
                        onClose={() => setPreviewImage(null)}
                    />
                )}
            </AnimatePresence>
        </>
    );
}
