interface AvatarProps {
    userName: string;
    fromUser: boolean;
}

export default function Avatar({ userName, fromUser }: AvatarProps) {
    if (fromUser) return null;
    const initials = userName
        .split(" ")
        .map((item) => item[0])
        .join("");

    return (
        <div className={`flex items-center justify-center w-10 h-10 text-black rounded-full bg-gray-100`}>
            <span className="text-sm font-semibold">{initials}</span>
        </div>
    );
}
