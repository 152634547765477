export enum RequestState {
  IDLE = "idle",
  ACCEPT = "accept",
  IN_PROGRESS = "in-progress",
  SENT = "sent",
  REJECT = "reject",
}

export function getRequestStateName(state: RequestState) {
  switch (state) {
    case RequestState.IDLE:
      return "idle";
    case RequestState.ACCEPT:
      return "accept";
    case RequestState.IN_PROGRESS:
      return "in progress";
    case RequestState.SENT:
      return "sent";
    case RequestState.REJECT:
      return "reject";
  }
}
export type RequestsStatus = {
  totalNewRequest: number;
  newRequests: Request[];
  totalTickets: number;
  tickets: Request[];
};
export type Request = {
  _id: string;

  owner: string;
  patient: {
    userName: string;
    phone: string;
    _id: string;
  };
  file?: {
    size: number;
    name: string;
    url: string;
  };
  desc?: string;
  state: RequestState;
  comment?: string;
  service: string;
  ownerNewMessages: number;
  patientNewMessage: number;
  createdAt: string;
  updatedAt: string;
};
