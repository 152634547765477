import Chart from "./components/charts";
import RecentRequests from "./requests";
import RecentFollowUps from "./followups";
import RecentTickets from "./tickets";
import { useAppStore } from "../../stores/app-store";
import { useShallow } from "zustand/shallow";

export default function Dashboard() {
  const requestsStatus = useAppStore(
    useShallow((state) => state.requestsStatus)
  );
  // const requestsStatus = useQuery({
  //   queryKey: ["requests", "status"],
  //   queryFn: () =>
  //     apiCaller<{
  //       totalNewRequest: number;
  //       newRequests: Request[];
  //       totalTickets: number;
  //       tickets: Request[];
  //     }>({
  //       url: `/owners/${userId}/requests/status`,
  //       method: "GET",
  //     }).then((res) => {
  //       setRequestsStatus({
  //         total: res.totalNewRequest,
  //         newTickets: res.totalTickets,
  //         new: 0,
  //       });
  //       return res;
  //     }),
  //   ...DEFAULT_PULL,
  // });

  return (
    <div className="w-full h-full overflow-auto sm:max-h-[90vh] max-sm:max-h-[81vh] max-sm:min-h-[81vh] max-sm:p-2 sm:p-4 gap-4 flex flex-col">
      <div className="w-auto flex gap-4 max-lg:flex-col">
        <RecentTickets
          total={requestsStatus.totalTickets}
          tickets={requestsStatus.tickets}
          isLoading={false}
        />
        <Chart />
      </div>
      <div className="w-auto min-h-[300px] flex gap-4 max-lg:flex-col">
        <RecentRequests
          total={requestsStatus.totalNewRequest}
          tickets={requestsStatus.newRequests}
          isLoading={false}
        />
        <RecentFollowUps />
      </div>
    </div>
  );
}
