import * as React from "react";
import { Button, getTheme, Label, TextInput } from "flowbite-react";
import logo from "../../assets/images/logo.png";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { MouseEventHandler, useState } from "react";
import ClassNames from "classnames";
import { useMutation } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { User } from "../../types/user";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import validate from "../../utils/yupValidate";
import { YupValidationError } from "../../types/yup.error";
import { useAuthStore } from "../../stores/auth-store";
import { StatusCodes } from "http-status-codes";
import toastError from "../../utils/toastError";

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});
export function Login() {
  console.log("heare in login page");
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<
    YupValidationError<yup.InferType<typeof loginSchema>>
  >({});
  const setUser = useAuthStore((state) => state.setUSer);
  const navigate = useNavigate();
  const location = useLocation();
  const login = useMutation({
    mutationFn: (input: { email: string; password: string }) =>
      apiCaller<User>({
        method: "POST",
        url: "/auth/owner/login",
        body: JSON.stringify(input),
        errorHandler: (error) => {
          switch (error.status) {
            case StatusCodes.UNAUTHORIZED:
              return toastError({ title: "invalid username or password" });
            default:
              return toastError({
                title: "somthing happen wrong please try again later",
              });
          }
        },
      }),
    onSuccess: (data) => {
      setUser(data);
      const from = (location.state as any)?.from?.pathname || "/";
      navigate(from, { replace: true });
    },
  });

  return (
    <div className="w-screen h-screen flex items-center justify-center bg-neutral-50">
      <form
        className="flex w-full  max-w-[400px] flex-col gap-4 shadow-md scale-in bg-white rounded-lg p-8 sm:min-w-[400px]"
        onSubmit={async (event) => {
          event.preventDefault();
          // event.stopPropagation();
          const formData = new FormData(event.target as any);
          const data = {
            email: formData.get("email") as string,
            password: formData.get("password") as string,
          };
          const error = await validate(loginSchema, data);
          if (error) {
            return setError(error);
          }
          setError({});
          login.mutate(data);
        }}
      >
        <div className="flex flex-col w-auto justify-center gap-2 items-center">
          <img src={logo} alt="logo" className="" width={50} height={50} />
          <p className="title !font-bold capitalize text-neutral-700 text-2xl">
            admin panel
          </p>
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Your email" />
          </div>
          <TextInput
            name="email"
            type="email"
            placeholder="email@gmail.com"
            color={error.email ? "failure" : ""}
            helperText={error.email?.message}
          />
        </div>
        <div className="w-full">
          <div className="mb-2 block">
            <Label htmlFor="password" value="Your password" />
          </div>
          <div className=" relative w-full">
            <TextInput
              name="password"
              type={show ? "text" : "password"}
              style={{ paddingRight: "2.5rem" }}
              className=" w-full"
              color={error.password ? "failure" : ""}
              helperText={error.password?.message}
            />
            <button
              type="button"
              className="absolute right-4 top-3"
              onClick={(e) => {
                e.stopPropagation();
                setShow(!show)
              }}>
              {show ? <FaEye className="text-neutral-600" /> : <FaEyeSlash className="text-neutral-600" />}
            </button>
          </div>
        </div>
        <div>
          <p className="max-sm:text-xs sm:text-sm text-neutral-600 capitalize">if you want to signup، contact us.</p>
        </div>
        <Button type="submit" disabled={login.isPending}>
          Login
        </Button>
      </form>
    </div>
  );
}
