import { Button, Pagination } from "flowbite-react";
import { useState } from "react";
import AddPatientModal from "./modals";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { useAuthStore } from "../../stores/auth-store";
import apiCaller from "../../utils/api-caller";
import { Patient } from "../../types/patient";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { SquareSkeleton } from "../../components/skeleton";
export function Patients() {
  const [show, setShow] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [name, setName] = useState<string>("");
  const onSearch = useDebouncedCallback((e: any) => {
    setName(e.target.value);
  }, 300);
  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.user?._id);
  const { data, isLoading } = useQuery({
    queryKey: ["patients", page, { name }],
    queryFn: () =>
      apiCaller<{
        length: number;
        patients: Patient[];
      }>({
        method: "GET",
        url: `/owner/${userId}/patients`,
        params: { page, size: PAGINATION_SIZE, name },
      }),

    placeholderData: keepPreviousData,
    ...DEFAULT_CACHE_CONFIG,
  });
  return (
    <div className="w-full h-full flex flex-col gap-5 max-sm:px-2 max-sm:pt-2 sm:p-4">
      <div className="flex max-sm:flex-col sm:justify-between gap-4 max-sm:gap-3 w-full">
        <div
          className={
            "!w-[450px] shadow-sm  flex gap-2 items-end bg-blue-50 p-4 rounded-md"
          }
        >
          <div className="relative w-full max-w-md">
            <label className="block mb-2 text-sm font-medium text-gray-900 capitalize">
              search patients
            </label>
            <input
              type="search"
              id="default-search"
              className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white h-[41.6px] focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search  ..."
              onChange={onSearch}
            />
          </div>
        </div>

        <Button
          className="bg-neutral-200 text-xs text-nowrap h-fit sm:text-sm w-fit hover:text-white text-black"
          onClick={() => setShow(true)}
        >
          + Add Patient
        </Button>
      </div>
      <div className="flex flex-col justify-between h-full">
        <div className="relative sm:max-h-[63vh] sm:min-h-[63vh] max-sm:max-h-[49vh] max-sm:min-h-[49vh] overflow-auto ">
          <table className="w-full bg-white shadow-md">
            <thead className="bg-neutral-200 sticky top-0">
              <th className="text-xs sm:text-sm px-2 text-left uppercase py-3">
                username
              </th>
              <th className="text-xs sm:text-sm px-2 text-left uppercase py-3">
                phone
              </th>

              <th className="text-xs sm:text-sm px-2 text-left uppercase py-3">
                email
              </th>
              <th className="text-xs sm:text-sm px-2 text-left uppercase py-3">
                location
              </th>
            </thead>
            <tbody className="divide-y shadow-sm">
              {isLoading ? (
                <>
                  <tr className="bg-white hover:bg-neutral-100 cursor-pointer border-x border-neutral-200">
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-neutral-100 cursor-pointer border-x border-neutral-200">
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white hover:bg-neutral-100 cursor-pointer border-x border-neutral-200">
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-xs sm:text-sm p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                </>
              ) : (
                data?.patients.map((patient, indx) => {
                  return (
                    <tr
                      onClick={() => navigate(`/patients/${patient._id}`)}
                      key={patient._id}
                      className="bg-white hover:bg-neutral-100 cursor-pointer border-x border-neutral-200"
                    >
                      <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                        {patient.userName}
                      </td>
                      <td className="text-xs p-2 sm:text-sm whitespace-nowrap font-medium text-gray-900">
                        {patient.phone}
                      </td>
                      <td className="text-xs sm:text-sm p-2">
                        {patient.email}
                      </td>
                      <td className="text-xs sm:text-sm p-2">
                        {patient.province}-{patient.city}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          previousLabel="prev"
          className="text-xs sm:text-sm"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: ".35.cssrem 0",
          }}
          totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
          onPageChange={(page) => setPage(page)}
        />
      </div>
      <AddPatientModal show={show} onClose={() => setShow(false)} />
    </div>
  );
}
