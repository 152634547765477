import { Dropdown, Modal, Pagination, Select } from "flowbite-react";
import moment from "moment";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { getRequestStateName, RequestState } from "../../types/request-status";
import { useState } from "react";
import { ImAttachment } from "react-icons/im";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { getTotalPages } from "../../utils/pagination";
import { PAGINATION_SIZE } from "../../constants/pagination";
import { data, Link, useNavigate } from "react-router-dom";
import { getFileLink } from "../../utils/file";
import DateFilter from "../../components/filters/date-filter";
import { useDebouncedCallback } from "use-debounce";
import { SquareSkeleton } from "../../components/skeleton";
import { Request } from "../../types/request";
import { DEFAULT_PULL } from "../../constants/api";
export function Requests() {
  const [query, setQuery] = useState<{
    state?: RequestState | "all" | "tickets";
    name?: string;
    from?: Date | null;
    to?: Date | null;
  }>({
    from: null,
    to: null,
  });
  const [page, setPage] = useState<number>(1);
  const [desc, setdesc] = useState<string | undefined>("");
  const [show, setshow] = useState<boolean>(false);

  const onSearch = useDebouncedCallback((e: any) => {
    setQuery((prev) => ({ ...prev, name: e.target.value }));
  }, 300);

  const navigate = useNavigate();
  const userId = useAuthStore((state) => state.user?._id);
  const requests = useQuery({
    queryKey: ["requests", page, query],
    queryFn: () =>
      apiCaller<{ length: number; requests: Request[] }>({
        method: "GET",
        url: `/owners/${userId}/requests`,
        params: {
          page,
          size: PAGINATION_SIZE,
          ...Object.fromEntries(
            Object.entries(query).filter(([_, value]) => value !== null)
          ),
        },
      }),
    placeholderData: keepPreviousData,
    ...DEFAULT_PULL,
  });
  const queryClient = useQueryClient();
  const changeRequestState = useMutation({
    mutationFn: (data: { requestId: string; state: RequestState }) =>
      apiCaller<void>({
        method: "POST",
        url: `/owners/${userId}/requests/${data.requestId}/state`,
        body: JSON.stringify({ state: data.state }),
      }),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["requests"] });
    },
  });

  return (
    <div className="overflow-hidden w-full  h-full sm:max-h-[90vh] max-sm:max-h-[82vh]  max-sm:min-h-[82vh] flex flex-col gap-5 max-sm:px-2 max-sm:pt-2 sm:p-4">
      <div
        className={
          "shadow-sm w-full flex gap-2 items-end bg-blue-50 p-4 rounded-md"
        }
      >
        <div className="relative w-full max-w-md">
          <label className="block mb-2 text-sm font-medium text-gray-900 capitalize">
            search requests
          </label>
          <input
            type="search"
            onChange={onSearch}
            className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white h-[41.6px] focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search  ..."
          />
        </div>
        <DateFilter
          from={query?.from}
          to={query?.to}
          onChange={(e) => setQuery((prev) => ({ ...prev, ...e }))}
        />
        <div className="flex flex-col w-1/2 max-w-xs">
          <label className="block mb-2 text-sm font-medium text-gray-900">
            State:
          </label>
          <Select
            id="countries"
            className="bg-white rounded-lg w-full"
            onChange={(e) => {
              const id = e.target.options[e.target.selectedIndex].dataset.id;
              setQuery((prev) => ({ ...prev, state: id as any }));
            }}
          >
            {[
              { name: "All", id: "all" },
              { name: "Tickets", id: "tickets" },
              { name: "New", id: RequestState.IDLE },
              { name: "Accept", id: RequestState.ACCEPT },
              { name: "In Progress", id: RequestState.IN_PROGRESS },
              { name: "Sent", id: RequestState.SENT },
              { name: "Reject", id: RequestState.REJECT },
            ].map((item, index) => {
              return (
                <option key={item.id} data-id={item.id}>
                  {item.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>

      <div className="flex flex-col justify-between max-sm:max-h-[67vh] max-sm:min-h-[67vh] h-full">
        <div className="overflow-auto sm:min-h-[64vh] sm:max-h-[64vh] max-sm:max-h-[60vh] max-sm:min-h-[60vh]">
          <table className="w-full">
            <thead className="left-0 z-[5] sticky bg-neutral-200 top-0">
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                service
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                patient
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                phone
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                Date
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                Status
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                Description
              </th>
              <th className="sm:text-sm max-sm:text-xs text-left uppercase py-3 px-2">
                <span className="sr-only">Edit</span>
              </th>
            </thead>
            <tbody className="divide-y shadow-sm">
              {requests.isLoading || requests.isFetching ? (
                <>
                  <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                    <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 hover:text-blue-700">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="w-1/3 px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm flex items-center gap-4 max-sm:text-xs p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                    <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 hover:text-blue-700">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="w-1/3 px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm flex items-center gap-4 max-sm:text-xs p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                  <tr className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800">
                    <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 ">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm max-sm:text-xs p-2 hover:text-blue-700">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="w-1/3 px-1">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                    <td className="text-sm flex items-center gap-4 max-sm:text-xs p-2">
                      <SquareSkeleton className="w-full h-5" />
                    </td>
                  </tr>
                </>
              ) : (
                requests.data?.requests.map((request, indx) => {
                  return (
                    <tr
                      onClick={() => navigate(`/requests/${request._id}/chat`)}
                      key={request._id}
                      className="bg-white cursor-pointer hover:bg-neutral-100 border-x border-neutral-200 dark:border-gray-700 dark:bg-gray-800"
                    >
                      <td className="text-sm max-sm:text-xs p-2 whitespace-nowrap text-gray-900 dark:text-white">
                        {request.service}
                      </td>
                      <td className="text-sm max-sm:text-xs p-2 ">
                        {request.patient.userName}
                      </td>
                      <td className="text-sm max-sm:text-xs p-2 ">
                        {request.patient?.phone || "-"}
                      </td>

                      <td className="text-sm max-sm:text-xs p-2 text-nowrap">
                        {moment(request.createdAt).format("DD MMM YYYY, hh:mm")}
                      </td>
                      <td
                        className="text-sm max-sm:text-xs p-2 hover:text-blue-700"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Dropdown
                          label={request.state}
                          inline
                          arrowIcon={false}
                        >
                          {Object.values(RequestState).map((state) => (
                            <Dropdown.Item
                              key={state}
                              onClick={() => {
                                changeRequestState.mutate({
                                  requestId: request._id,
                                  state: state,
                                });
                              }}
                            >
                              {getRequestStateName(state)}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </td>
                      <td className="w-1/3 px-1">
                        {request?.desc ? (
                          <p
                            onClick={(e) => {
                              if (request?.desc) {
                                e.stopPropagation();

                                setdesc(request?.desc);
                                setshow(true);
                              }
                            }}
                            className="truncate cursor-pointer max-sm:line-clamp-2 sm:line-clamp-2 text-sm max-sm:text-xs text-wrap"
                          >
                            {request?.desc}
                          </p>
                        ) : (
                          "_"
                        )}
                      </td>
                      <td className="text-sm flex items-center gap-4 max-sm:text-xs p-2">
                        <Link
                          to={`/requests/${request._id}/chat`}
                          className="font-medium text-cyan-600 hover:underline dark:text-cyan-500"
                        >
                          <div className="w-fit relative">
                            <HiChatBubbleLeftRight size={20} />
                            {request.patientNewMessage ? (
                              <p className="absolute -top-2 -right-2 bg-neutral-700 text-white rounded-full flex items-center justify-center text-[10px] w-4 h-4">
                                {request.patientNewMessage}
                              </p>
                            ) : null}
                          </div>
                        </Link>
                        {request?.file?.name ? (
                          <a
                            download
                            href={getFileLink(request?.file?.url)}
                            className="font-medium hover:scale-105 duration-100 ease-linear transition-all text-cyan-600 hover:underline dark:text-cyan-500"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <ImAttachment size={20} />
                          </a>
                        ) : null}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          currentPage={page}
          className="text-xs sm:text-sm"
          previousLabel="prev"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          totalPages={getTotalPages(PAGINATION_SIZE, data?.length || 0)}
          onPageChange={setPage}
        />
      </div>
      <Modal show={show} onClose={() => setshow(false)}>
        <Modal.Header className="text-sm uppercase max-sm:text-xs">
          Request Description
        </Modal.Header>
        <Modal.Body>
          <p className="text-sm max-sm:text-xs">{desc}</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
