import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Layout from "./components/layout";
import { Requests } from "./pages/requests";
import { Patients } from "./pages/patients";
import { Followups } from "./pages/followups";
import Chat from "./pages/Chat";
import { Login } from "./pages/login";
import { ForgetPass } from "./pages/forget-pass";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ProtectRoute from "./components/ProtextRoute";
import Followup from "./pages/followups/followup";
import PatientPage from "./pages/patients/patient";

const client = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectRoute>
                <Layout />
              </ProtectRoute>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="/requests" element={<Requests />} />
            <Route path="/requests/:request/chat" element={<Chat />} />
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/:patientId" element={<PatientPage />} />
            <Route path="/followups" element={<Followups />} />
            <Route path="/followups/:followup" element={<Followup />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPass />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
