import { Sidebar } from "flowbite-react";
import { HiChartPie, HiInbox, HiTable, HiUser } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5";
import getRequestsStatusLable from "./utils/get-requests-status-lable";
import SideBarItem from "./components/SidebarItem";
import { useAppStore } from "../../stores/app-store";
import { useQuery } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { DEFAULT_PULL } from "../../constants/api";
import { Request } from "../../types/request";

export default function SideBar({
  active,
  handleActiveTab,
  toggleModal,
}: {
  active: string;
  handleActiveTab: (e: string) => void;
  toggleModal: (e: boolean) => void;
}) {
  const userId = useAuthStore((state) => state.user?._id);
  const setRequestsStatus = useAppStore((state) => state.setRequestsStatus);
  const requestsStatus = useQuery({
    queryKey: ["requests", "status"],
    queryFn: () =>
      apiCaller<{
        totalNewRequest: number;
        newRequests: Request[];
        totalTickets: number;
        tickets: Request[];
      }>({
        url: `/owners/${userId}/requests/status`,
        method: "GET",
      }).then((res) => {
        setRequestsStatus(res);
        return res;
      }),
    ...DEFAULT_PULL,
  });

  return (
    <Sidebar
      collapseBehavior="collapse"
      aria-label="Sidebar with multi-level dropdown example"
      className="h-[91vh] max-md:hidden min-h-[91vh]"
    >
      <Sidebar.Items>
        <Sidebar.ItemGroup>
          <SideBarItem
            active={active === "dashboard"}
            onClick={() => handleActiveTab("dashboard")}
            href="/"
            icon={HiChartPie}
          >
            Dashboard
          </SideBarItem>

          <SideBarItem
            active={active === "requests"}
            onClick={() => handleActiveTab("requests")}
            href="/requests"
            icon={HiInbox}
            labelColor={"blue"}
            label={getRequestsStatusLable(requestsStatus.data || {})}
          >
            Requests
          </SideBarItem>
          <SideBarItem
            active={active === "patients"}
            onClick={() => handleActiveTab("patients")}
            href="/patients"
            icon={HiUser}
          >
            Patients
          </SideBarItem>
          <SideBarItem
            active={active === "followups"}
            onClick={() => handleActiveTab("followups")}
            href="/followups"
            icon={HiTable}
          >
            FollowUps
          </SideBarItem>
          <SideBarItem
            active={active === "logout"}
            onClick={() => {
              toggleModal(true);
              handleActiveTab("logout");
            }}
            icon={IoLogOut}
          >
            Logout
          </SideBarItem>
        </Sidebar.ItemGroup>
      </Sidebar.Items>
    </Sidebar>
  );
}
