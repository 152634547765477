import { useEffect, useRef } from "react";

export const useScrollToBottom = <T extends HTMLElement>(
  deps: any[] = [],
  forbidden = false
) => {
  const containerRef = useRef<T | null>(null);
  const scrollToBottom = (smooth: boolean = true) => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
    }
  };
  useEffect(() => {
    if (!forbidden) {
      const timeout = setTimeout(() => {
        scrollToBottom();
      }, 0);
      return () => clearTimeout(timeout);
    }
  }, deps);
  const triggerScrollToBottom = () => {
    setTimeout(scrollToBottom, 10);
  };
  return { containerRef, triggerScrollToBottom };
};
