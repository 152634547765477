import { ListGroup, Tabs, TabsRef } from "flowbite-react";
import { useRef } from "react";
import { setFollowup } from "../../stores/followup-store";
import { useQuery } from "@tanstack/react-query";
import { DEFAULT_CACHE_CONFIG } from "../../constants/api";
import { FolloupEventMetadata, FollowupsStatus } from "../../types/followup";
import apiCaller from "../../utils/api-caller";
import { useAuthStore } from "../../stores/auth-store";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { StatusCodes } from "http-status-codes";
import { SquareSkeleton } from "../../components/skeleton";

export default function RecentFollowUps() {
  const navigate = useNavigate();
  const tabsRef = useRef<TabsRef>(null);
  const calender = useAuthStore((state) => state.user?.calender);
  const resetCalender = useAuthStore((state) => state.resetCalender);
  const followupsStatus = useQuery({
    queryKey: ["followups", "status"],
    queryFn: () =>
      apiCaller<FollowupsStatus>({
        method: "GET",
        url: "/followups/status",
        errorHandler(error) {
          if (error.status === StatusCodes.INTERNAL_SERVER_ERROR) {
            resetCalender();
          }
        },
      }),

    enabled: !!calender?.id,
    ...DEFAULT_CACHE_CONFIG,
  });
  if (!calender?.id)
    return (
      <div className="flex-col gap-4 min-h-[300px] !font-pinar shadow-md w-full p-4 h-max min-w-max rounded-md bg-neutral-50">
        <div className="w-full flex items-center justify-between">
          <p className="title capitalize font-medium">recent FollowUps</p>
          <p className="w-fit h-auto text-neutral-500 text-sm">no items!</p>
        </div>
        <div className="w-full h-full flex items-center justify-center min-h-[30vh]">
          <Link
            to={"/followups"}
            className="rounded-md bg-blue-500 px-4 py-2 text-white text-sm max-sm:text-xs"
          >
            Give Access to Use Followups
          </Link>
        </div>
      </div>
    );

  return (
    <div className="bg-neutral-50 gap-3 border border-neutral-200 flex flex-col rounded-lg py-3 px-5 shadow-md lg:max-w-[50%] w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium">recent FollowUps</p>
        <p className="w-fit h-auto text-neutral-500 text-sm">10 items</p>
      </div>
      <Tabs aria-label="Default tabs" variant="default" ref={tabsRef}>
        <Tabs.Item
          active
          className="!p-2"
          title={`Today (${
            followupsStatus?.data?.todayFollowups?.length || 0
          })`}
        >
          <div className="relative overflow-x-auto">
            {followupsStatus.isLoading ? (
              <ListGroup className="w-full max-h-[25vh] min-w-full overflow-auto">
                {[1, 2, 3, 4].map((item) => {
                  console.log({ isLoading: followupsStatus.isLoading, item });
                  return (
                    <ListGroup.Item key={item}>
                      <div className="grid w-full items-center grid-cols-3 space-x-2">
                        <SquareSkeleton className="col-span-1 h-5" />
                        <SquareSkeleton className="col-span-1 h-5" />
                        <SquareSkeleton className="col-span-1 h-5" />
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ) : followupsStatus?.data?.todayFollowups &&
              followupsStatus?.data?.todayFollowups.length > 0 ? (
              <ListGroup className="w-full max-h-[25vh] min-w-full overflow-auto">
                {followupsStatus?.data?.todayFollowups.map((item) => {
                  return (
                    <ListGroup.Item
                      onClick={() => {
                        setFollowup(item);
                        navigate(`/followups/${item.id}`);
                      }}
                    >
                      <div className="grid w-full items-center grid-cols-3 space-x-2">
                        <p className="text-black text-normal">{item.summary}</p>
                        <p className="text-neutral-500 text-sm font-normal">
                          {(
                            item.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.name || "not selected"}
                        </p>
                        <p className="text-neutral-500 text-xs text-center font-light">
                          {moment(item.start.dateTime).format(
                            "MMM DD YYYY, hh:mm"
                          )}
                        </p>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ) : (
              <p className="text-neutral-500 w-full text-center mt-8 max-sm:text-xs sm:text-sm capitalize ">
                you dont have any followup today.
              </p>
            )}
          </div>
        </Tabs.Item>
        <Tabs.Item
          active
          title={`Recent (${
            followupsStatus?.data?.nearFollowups?.length || 0
          })`}
        >
          <div className="relative overflow-x-auto">
            {followupsStatus?.isLoading ? (
              <ListGroup className="w-full max-h-[25vh] min-w-full overflow-auto">
                {[1, 2, 3, 4].map((item) => (
                  <ListGroup.Item key={item}>
                    <div className="grid w-full items-center grid-cols-3 space-x-2">
                      <SquareSkeleton className="col-span-1 h-5" />
                      <SquareSkeleton className="col-span-1 h-5" />
                      <SquareSkeleton className="col-span-1 h-5" />
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            ) : followupsStatus.data?.nearFollowups &&
              followupsStatus.data?.nearFollowups.length > 0 ? (
              <ListGroup className="w-full max-h-[30vh] min-w-[400px] overflow-auto">
                {followupsStatus.data?.nearFollowups.map((item) => {
                  return (
                    <ListGroup.Item
                      onClick={() => {
                        setFollowup(item);
                        navigate(`/followups/${item.id}`);
                      }}
                    >
                      <div className="grid w-full items-center grid-cols-3 space-x-2">
                        <p className="text-black text-normal">{item.summary}</p>
                        <p className="text-neutral-500 text-sm font-normal">
                          {(
                            item.extendedProperties
                              ?.shared as FolloupEventMetadata
                          )?.name || "not selected"}
                        </p>
                        <p className="text-neutral-500 text-xs text-center font-light">
                          {moment(item.start.dateTime).format(
                            "MMM DD YYYY, hh:mm"
                          )}
                        </p>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            ) : (
              <p className="text-neutral-500 w-full text-center mt-8 max-sm:text-xs sm:text-sm capitalize ">
                no recent followup!
              </p>
            )}
          </div>
        </Tabs.Item>
      </Tabs>
    </div>
  );
}
