import { ListGroup } from "flowbite-react";
import moment from "moment";
import { Request } from "../../types/request";
import { services } from "../../constants/services";
import { useNavigate } from "react-router-dom";
import { SquareSkeleton } from "../../components/skeleton";

export default function RecentRequests({
  total,
  tickets,
  isLoading,
}: {
  total: number;
  tickets: Request[];
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div className="bg-neutral-50 flex flex-col border-neutral-200 px-5 py-3 gap-3 rounded-lg shadow-md lg:max-w-[50%] w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium">recent requests</p>
        <p className="w-fit h-auto text-neutral-500 text-sm">{total} items</p>
      </div>
      <div className="relative overflow-x-auto">
        {isLoading ? (
          <ListGroup className="w-full max-h-[25vh] min-w-full overflow-auto">
            {[1, 2, 3, 4].map((item) => (
              <ListGroup.Item>
                <div className="grid w-full items-center grid-cols-4 space-x-2">
                  <SquareSkeleton className=" col-span-2 h-5" />
                  <SquareSkeleton className=" col-span-1 h-5" />
                  <SquareSkeleton className=" col-span-1 h-5" />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : tickets.length > 0 ? (
          <ListGroup className="w-full max-h-[25vh] min-w-full overflow-auto">
            {tickets.map((item) => {
              return (
                <ListGroup.Item
                  onClick={() => {
                    navigate("/requests");
                  }}
                >
                  <div className="grid w-full items-center grid-cols-4 space-x-2">
                    <div className="flex col-span-2 items-center gap-2">
                      {
                        services.find(
                          (service) => service.name === item.service
                        )?.icon
                      }
                      <p className="text-black text-left text-sm text-normal">
                        {item.service}
                      </p>
                    </div>
                    <p className="text-neutral-500 text-left text-sm font-normal">
                      {item.patient.userName}
                    </p>
                    <p className="text-neutral-500 text-xs text-center font-light">
                      {moment(item.createdAt).format("MMM DD, hh:mm")}
                    </p>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <p className="text-neutral-500 w-full text-center mt-8 max-sm:text-xs sm:text-sm capitalize ">
            no recent requests!
          </p>
        )}
      </div>
    </div>
  );
}
