export const DEFAULT_CACHE_CONFIG = {
  staleTime: Infinity, // Cache remains fresh until a page refresh
  refetchOnWindowFocus: false, // Disable refetching when the user switches back to the tab
  refetchOnMount: false, // Prevent refetching when the component remounts
  refetchOnReconnect: true, // Good
  //   refetchOnReconnect: false, // Prevent refetching on network reconnection
};

export const DEFAULT_PULL = {
  refetchOnMount: true,
  refetchInterval: 1000 * 30,
};
