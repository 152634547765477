import { create } from "zustand";
import { RequestsStatus } from "../types/request";
import { createJSONStorage, persist } from "zustand/middleware";

interface AppStore {
  activeTab: string;
  setActiveTab: (tab: string) => void;
  requestsStatus: RequestsStatus;
  setRequestsStatus: (status: Partial<RequestsStatus>) => void;
}

export const useAppStore = create<AppStore>(
  //@ts-ignore
  persist(
    (set, get) => ({
      activeTab: "dashboard",
      setActiveTab(tab) {
        set({ activeTab: tab });
      },
      requestsStatus: {
        totalNewRequest: 0,
        newRequests: [],
        totalTickets: 0,
        tickets: [],
      },
      setRequestsStatus(status) {
        set({
          requestsStatus: {
            ...get().requestsStatus,
            ...status,
          },
        });
      },
    }),
    {
      name: "app-store",
      storage: createJSONStorage(() => localStorage),
      partialize(state) {
        return { activeTab: state.activeTab };
      },
    }
  )
);
