import { FaTimes, FaDownload } from "react-icons/fa";
import { motion } from "framer-motion";

interface FilePreviewProps {
    imageUrl: string;
    onClose: () => void;
}

export default function FilePreview({ imageUrl, onClose }: FilePreviewProps) {
    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
            onClick={onClose}
        >
            <div className="relative">
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 bg-white rounded-full p-2 shadow-md"
                >
                    <FaTimes className="text-gray-600" />
                </button>
                <a
                    href={imageUrl}
                    download
                    className="absolute top-4 right-16 bg-white rounded-full p-2 shadow-md"
                >
                    <FaDownload className="text-gray-600" />
                </a>
                <img
                    src={imageUrl}
                    alt="Preview"
                    width={600}
                    height={600}
                    className="rounded-lg"
                />
            </div>
        </motion.div>
    );
}
