import { HiChartPie, HiInbox, HiTable, HiUser } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

export default function NavBar({
  active,
  handleActiveTab,
}: {
  active: string;
  handleActiveTab: (e: string) => void;
}) {
  const navigate = useNavigate();
  return (
    <div className="sticky sm:hidden z-50 w-full h-16 bg-white border border-gray-200 bottom-0 left-1/2 dark:bg-gray-700 dark:border-gray-600">
      <div className="grid h-full grid-cols-4 mx-auto">
        <button
          onClick={() => {
            handleActiveTab("dashboard");
            navigate("/");
          }}
          data-tooltip-target="tooltip-home"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear  ${
            active === "dashboard" && "bg-gray-100 border-blue-600"
          } hover:bg-gray-100 hover:border-blue-600 border-b-[3px] group`}
        >
          <HiChartPie
            className={`w-6 h-6 mb-1 text-gray-500 group-hover:text-blue-600  ${
              active === "dashboard" && "text-blue-600"
            }`}
          />
          <span className="sr-only">dashboard</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("requests");
            navigate("/requests");
          }}
          data-tooltip-target="tooltip-wallet"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${
            active === "requests" && "bg-gray-100 border-blue-600"
          } hover:bg-gray-100 hover:border-blue-600 border-b-[3px] group`}
        >
          <HiInbox
            className={`w-6 h-6 mb-1 text-gray-500 group-hover:text-blue-600 ${
              active === "requests" && "text-blue-600"
            }`}
          />
          <span className="sr-only">requests</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("patients");
            navigate("/patients");
          }}
          data-tooltip-target="tooltip-wallet"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${
            active === "patients" && "bg-gray-100 border-blue-600"
          } hover:bg-gray-100 hover:border-blue-600 border-b-[3px] group`}
        >
          <HiUser
            className={`w-6 h-6 mb-1 text-gray-500 group-hover:text-blue-600 ${
              active === "patients" && "text-blue-600"
            }`}
          />
          <span className="sr-only">Patients</span>
        </button>
        <button
          onClick={() => {
            handleActiveTab("followups");
            navigate("/followups");
          }}
          data-tooltip-target="tooltip-settings"
          type="button"
          className={`inline-flex flex-col items-center justify-center px-5 transition-all duration-100 ease-linear ${
            active === "followups" && "bg-gray-100 border-blue-600"
          } hover:bg-gray-100 hover:border-blue-600 border-b-[3px] group`}
        >
          <HiTable
            className={`w-6 h-6 mb-1 text-gray-500 group-hover:text-blue-600 ${
              active === "followups" && "text-blue-600"
            }`}
          />
          <span className="sr-only">followups</span>
        </button>
      </div>
    </div>
  );
}
