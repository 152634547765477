import { ListGroup } from "flowbite-react";
import moment from "moment";
import { HiChatBubbleLeftRight } from "react-icons/hi2";
import { Request } from "../../types/request";
import { services } from "../../constants/services";
import { useNavigate } from "react-router-dom";
import { SquareSkeleton } from "../../components/skeleton";

export default function RecentTickets({
  total,
  tickets,
  isLoading,
}: {
  total: number;
  tickets: Request[];
  isLoading: boolean;
}) {
  const router = useNavigate();
  return (
    <div className="bg-neutral-50 gap-2 flex min-h-[200px] flex-col border border-neutral-200 rounded-lg py-3 px-5 shadow-md lg:max-w-[80%] w-full">
      <div className="w-full flex items-center justify-between">
        <p className="title capitalize font-medium">recent Tickets</p>
        <p className="w-fit h-auto text-neutral-500 text-sm">{total} items</p>
      </div>
      <div className="relative overflow-x-auto">
        {isLoading ? (
          <ListGroup>
            {[1, 2, 3].map((item) => (
              <ListGroup.Item key={item}>
                <div className="grid w-full items-center grid-cols-5 space-x-2">
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                  <SquareSkeleton className="w-full h-5" />
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : tickets.length > 0 ? (
          <ListGroup className="w-full max-h-[30vh] overflow-auto">
            {tickets.map((ticket) => {
              return (
                <ListGroup.Item
                  key={ticket._id}
                  onClick={() => router(`/requests/${ticket._id}/chat`)}
                >
                  <div className="grid w-full items-center grid-cols-5 space-x-2">
                    <div className="flex w-full items-center col-span-2 gap-1">
                      {
                        services.find((item) => item.name === ticket.service)
                          ?.icon
                      }
                      <p className="text-black text-sm text-normal">
                        {ticket.service}
                      </p>
                    </div>
                    <p className="text-neutral-500 w-full text-sm font-normal">
                      {ticket.patient.userName}
                    </p>
                    <p className="text-neutral-500 w-full text-xs text-center font-light">
                      {moment().format("MMM DD, hh:mm")}
                    </p>
                    <div
                      className={`${
                        ticket.patientNewMessage <= 0 && "hidden"
                      } w-full relative flex justify-end items-center`}
                    >
                      <HiChatBubbleLeftRight size={20} color="gray" />
                      <div className="w-4 -mt-3 h-4 rounded-full flex self-center items-center justify-center text-white bg-blue-500 border text-[10px]">
                        {ticket.patientNewMessage}
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        ) : (
          <p className="text-neutral-500 w-full text-center mt-8 max-sm:text-xs sm:text-sm capitalize ">
            no recent tickets yet!!
          </p>
        )}
      </div>
    </div>
  );
}
