import { Tabs } from "flowbite-react";
import EmbeddedGoogleCalender from "../../components/GoogleCalender";
import CalenderList from "../../components/GoogleCalender/CalenderList";
import RequestAccess from "./components/RequestAccess";
import AddEventModal from "./components/AddEventModal";
import FollowupsTable from "./components/FollowupsTable";
import { useState } from "react";
import { useAuthStore } from "../../stores/auth-store";
import { MdAdd } from "react-icons/md";

export function Followups() {
  const [modal, setModal] = useState({
    add: false,
  });
  const calender = useAuthStore((state) => state.user?.calender);
  return calender?.access ? (
    <div className="w-full sm:max-h-[90vh] max-sm:max-h-[79.6vh] max-sm:min-h-[79.6vh] flex flex-col">
      <div className="flex p-4 justify-between flex-row w-full">
        <CalenderList />
        <button
          className="flex  px-4 py-2 shadow-sm border border-neutral-200 rounded-md w-max max-w-max min-w-[50px] justify-center bg-neutral-300 items-center"
          onClick={() => setModal((prev) => ({ ...prev, add: true }))}
        >
          <MdAdd />
          <p className="text-xs max-sm:hidden sm:text-sm"> Add</p>
        </button>
      </div>
      <Tabs>
        <Tabs.Item active title="Calender">
          <EmbeddedGoogleCalender calenderId={calender.id} />
        </Tabs.Item>
        <Tabs.Item title="Table">
          <FollowupsTable />
        </Tabs.Item>
      </Tabs>
      <AddEventModal
        show={modal.add}
        onClose={() => setModal((prev) => ({ ...prev, add: false }))}
      />
    </div>
  ) : (
    <div className="h-full min-h-[91vh] max-h-[91vh] max-sm:max-h-[80vh] max-sm:min-h-[80vh] flex items-center justify-center w-full">
      <RequestAccess />
    </div>
  );
}
