import { useRef, useState } from "react";
import { IoSend } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { RiDeleteBin6Line } from "react-icons/ri"; // Import delete icon
import { useChatStore } from "../../stores/chat-store";
import { useShallow } from "zustand/shallow";
import { useMutation } from "@tanstack/react-query";
import apiCaller from "../../utils/api-caller";
import { Message, MessageState } from "../../types/message";
import { useAuthStore } from "../../stores/auth-store";

export default function ChatInput({ chatId, onAfterSend }: { chatId: string; onAfterSend: () => void }) {
    const [fileName, setFileName] = useState<string | null>(null);
    const state = useChatStore(
        useShallow((state) => ({
            updateChat: state.updateChat,
            updateMessage: state.updateMessage,
            addMessage: state.addMessage,
            resetNewMessages: state.resetNewMessages,
        }))
    );
    const user = useAuthStore((state) => state.user);

    const sendMessageMutation = useMutation({
        mutationFn: (data: { id: string; message: string }) =>
            apiCaller<Message>({
                method: "POST",
                url: `/requests/${chatId}/messages`,
                body: JSON.stringify({ message: data.message }),
            }),
        onError(err, data) {
            state.updateMessage(chatId, data.id, {
                state: MessageState.FAIL,
            });
        },
        onSuccess(res, data) {
            state.updateMessage(chatId, data.id, {
                ...res,
                localId: data.id,
                state: MessageState.SENT,
            });
        },
    });
    const sendFileMessageMutation = useMutation({
        mutationFn: (data: { id: string; data: FormData }) =>
            apiCaller<Message>({
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                url: `/requests/${chatId}/messages`,
                body: data.data,
                formData: true,
            }),
        onError(err, data) {
            state.updateMessage(chatId, data.id, {
                state: MessageState.FAIL,
            });
        },
        onSuccess(res, data) {
            state.updateMessage(chatId, data.id, {
                ...res,
                localId: data.id,
                state: MessageState.SENT,
            });
        },
    });

    function sendMessage(message: string) {
        const localId = `${Date.now()}-${Math.random() * 1000}`;
        state.addMessage(chatId, {
            _id: localId,
            localId: localId,
            sender: {
                _id: user?._id as string,
                userName: user?.pharmacy as string,
            },
            message,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            state: MessageState.SENDING,
        });
        sendMessageMutation.mutate({ id: localId, message });
        onAfterSend();
    }
    function sendFile(file: File, message: string) {
        const localId = `${Date.now()}-${Math.random() * 1000}`;
        state.addMessage(chatId, {
            _id: localId,
            localId: localId,
            sender: {
                _id: user?._id as string,
                userName: user?.pharmacy as string,
            },
            file: {
                name: file.name,
                size: file.size,
                url: URL.createObjectURL(file),
            },
            ...(message.trim() && { message }),
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            state: MessageState.SENDING,
        });
        const formData = new FormData();
        formData.append("file", file);
        if(message.trim()){
          formData.append("message", message);
        }
        sendFileMessageMutation.mutate({ id: localId, data: formData });
        onAfterSend();
    }
    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleAttachmentClick = () => {
        if (fileInputRef.current) {
            //@ts-ignore
            fileInputRef.current.click();
        }
    };
    const handleDeleteFile = () => {
        setFileName(null); // Clear the file name
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Reset the file input
        }
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                const data = new FormData(e.target as any);
                const form = e.target as HTMLFormElement;
                const message = data.get("message") as string;

                if (fileName) {
                    const file = fileInputRef.current?.files?.[0];
                    if (file) {
                        sendFile(file, message);
                        handleDeleteFile();
                    }
                } else if (message.trim()) {
                    sendMessage(message);
                    form.reset();
                }
            }}
        >
            {fileName && (
                <div className="flex items-center gap-3 p-4 border border-gray-300 rounded-lg bg-gray-50 shadow-md -mb-8">
                    <div className="flex items-center justify-center w-12 h-12 bg-gray-100 rounded-full border border-gray-300">
                        <RiAttachment2
                            className="text-blue-800"
                            size={24}
                        />
                    </div>
                    <div className="flex-1">
                        <span className="block text-sm font-semibold text-gray-900 truncate">{fileName}</span>
                        <span className="text-xs text-gray-500">File ready to send</span>
                    </div>
                    <button
                        onClick={handleDeleteFile}
                        className="text-red-500 hover:bg-red-100 p-2 rounded-full"
                    >
                        <RiDeleteBin6Line size={20} />
                    </button>
                </div>
            )}
            <div className="relative flex items-end mt-10">
                <textarea
                    name="message"
                    className="w-full resize-none p-4 min-h-[73px] max-h-[120px] h-[73px] pr-20 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 shadow-md"
                    placeholder={fileName ? "Write Your Caption..." : "Write something..."}
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.preventDefault();
                            const message = (e.target as HTMLTextAreaElement).value;
                            if (message.trim()) {
                                sendMessage(message);
                                (e.target as HTMLTextAreaElement).value = "";
                            }
                        }
                    }}
                />
                <button
                    type="button"
                    onClick={handleAttachmentClick}
                    className="absolute right-16 p-2 top-1/2 -translate-y-1/2 rounded-full  hover:bg-gray-200"
                >
                    <RiAttachment2
                        className="text-blue-800"
                        size={24}
                    />
                </button>
                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={(e) => {
                        const files = e.target.files;
                        if (files?.length) {
                            setFileName(files[0].name);
                        }
                    }}
                />
                <button
                    type="submit"
                    className="absolute right-4 top-1/2 -translate-y-1/2 bg-blue-700 text-white p-3 rounded-full hover:bg-blue-800 shadow-md focus:ring-4 focus:ring-blue-300"
                >
                    <IoSend size={20} />
                </button>
            </div>
        </form>
    );
}
