export type SquareSkeletonProps = {} & React.HTMLAttributes<HTMLDivElement>;
export default function SquareSkeleton({
  className,
  ...props
}: SquareSkeletonProps) {
  return (
    <div
      //FIXME tw merge
      className={`bg-gray-200 rounded-full dark:bg-gray-700 
        ${className}`}
      {...props}
    />
  );
}
